.contact-section {
    padding: 2rem 5vw;
    color: #ffffff;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
}

.contact-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    border: #ffffff 1px solid;
    padding: 2rem;
}
.contact-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1.5rem;
    margin-top: 1rem;
}

.contact-link {
    background-color: rgb(221, 221, 221);
    color: #121212;
    text-decoration: none;
    font-size: 1.2rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 200px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-link:hover {
    background-color: #ffaa00;
    transform: scale(1.1);
}

.contact-link i {
    font-size: 1.5rem;
}

@media (max-width: 768px) {
    .contact-links {
        flex-direction: column;
        gap: 1rem;
    }
}