.skills-section {
    width: 100%;
    padding: 2rem 5vw;
    margin-top: 5%;
    color: #ffffff;
  }

.section-title {
  font-size: 5rem;
  margin-bottom: 1rem;
}

.skills-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.skill-card {
  padding: 0.5rem;
  border: #ffffff 1px solid;
  transition: transform 0.6s ease, opacity 0.6s ease;
  opacity: 0;
  transform: translateX(0);
}

.skill-card.in-view {
  opacity: 1;
  transform: translateX(0);
}

.scroll-left {
  transform: translateX(-100%);
}

.scroll-right {
  transform: translateX(100%);
}

.skill-card:hover {
  transform: translateY(-5px);
}

.skill-title {
    font-size: 1.8rem;
    color: #000000;
    -webkit-text-stroke: 1px #ffffff; /* Adds white outline */
    margin-bottom: 1rem;
    font-family: 'Inter', sans-serif;
  }

.skill-description {
  font-size: 1.2rem;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .skills-list {
    grid-template-columns: 1fr;
  }

  .section-title {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
}