.project-section {
    width: 100%;
    color: #ffffff;
  }
  
  .project-list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .project-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1.5rem;
    border-radius: 8px;
    opacity: 0; /* Initially hidden */
    transform: translateY(20px); /* Initially offset downwards */
    transition: opacity 0.6s ease, transform 0.6s ease;
  }

  .project-item:hover {
     .project-image {
        filter: grayscale(0%);
     }
  }

  .project-item.fade-in {
    opacity: 1; /* Fully visible when fade-in class is added */
    transform: translateY(0); /* Move back to original position */
  }

  .project-link {
    text-decoration: none; /* Remove text underline */
    color: inherit; /* Ensure text color stays the same */
  }
  
  .project-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-right: 2rem;
    border-radius: 8px;
    filter: grayscale(100%);
    transition: filter 0.3s ease-in-out; 
  }

  .project-image:hover {
    filter: grayscale(0%); /* On hover, image gets colored */
  }
  
  .project-details {
    flex-grow: 1;
  }
  
  .project-name {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }
  
  .project-technologies {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .project-description {
    font-size: 1.2rem;
    font-weight: lighter;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .project-item {
      flex-direction: column;
      align-items: center;
    }
  
    .project-image {
      margin-right: 0;
      margin-bottom: 1rem;
      width: 100%;
      max-width: 150px; /* Limit the size of the image on mobile */
      height: auto; /* Allow height to adapt */
    }
  
    .project-details {
      text-align: center;
    }

    .section-title {
        text-align: center;
      }
  }
  
  /* For smaller screens like phones */
  @media (max-width: 480px) {
    .project-image {
      max-width: 120px; /* Reduce the image size further for smaller phones */
    }

    .section-title {
        text-align: center;
      }
  }
  