.name-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: relative;
    background-color: #121212; /* Dark background */

  }
  
  /* Set font size based on 80% of the viewport width */
  .first-name, .last-name {
    font-size: calc(8vw * 0.7); /* 80% of the viewport width */
    font-weight: bold;
    color: #ffffff;
    transition: transform 0.1s ease-out;
    text-align: left;
    margin: 0 !important;
  }

  .title {
    font-weight: lighter;
    color: #ffffff;
    margin-top: 1rem; /* Add space between last name and title */
    text-align: center;
  }
  
  /* Align first name to the left */
  .first-name {
    align-self: flex-start;
  }
  
  /* Align last name to the right */
  .last-name {
    align-self: flex-end;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .first-name, .last-name {
      font-size: calc(10vw * 0.7); /* 80% of viewport width */
    }
  }
  
  @media (max-width: 768px) {
    .first-name, .last-name {
      font-size: calc(12vw * 0.7); /* Adjust for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .first-name, .last-name {
      font-size: calc(14vw * 0.7); /* 80% of viewport width for mobile */
    }
  
    .name-section {
      padding: 0 2vw; /* Further reduce padding for mobile */
    }
  }
  