.logo-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #121212; /* Dark background */
    transition: transform 1s ease-in-out;
    z-index: 10; /* Ensure it's on top of content */
  }
  
  .logo-container.move-up {
    transform: translateY(-100vh); /* Move the logo up */
  }
  
  .logo-image {
    width: 25%;
  }
  