  .title-no-margin {
    margin-top: 0;
  }
  
  .about-me-section {
    width: 100%;
    padding: 2rem 5vw;
    color: #ffffff;
  }
  
  .about-me-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
  }
  
  .about-me-text {
    text-align: left;
    align-self: flex-start;
    flex: 1;
    & p {
        width: 70%;
        font-size: 1.8rem;
        font-weight: lighter;
    }
  }
  
  .about-me-image-wrapper {
    position: relative;
    flex: 1;
    aspect-ratio: 1;
    box-shadow: inset 0 0 0 1px #ffffff;
    padding: 1px;
  }
  
  .about-me-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
    filter: grayscale(100%);
    transition: filter 0.3s ease-in-out;
  }

  .about-me-image:hover {
    filter: grayscale(0%);
  }
  
  .top-left-cover, .bottom-right-cover {
    position: absolute;
    background-color: #121212;
    z-index: 2;
    transition: width 0.5s ease, height 0.5s ease;
  }
  
  .top-left-cover {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 25%;
    min-height: 25%;
    border-right: #ffffff 1px solid;
    border-bottom: #ffffff 1px solid;
  }
  
  .bottom-right-cover {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    min-width: 25%;
    min-height: 25%;
    border-left: #ffffff 1px solid;
    border-top: #ffffff 1px solid;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .about-me-content {
      flex-direction: column;
    }

    .about-me-text {
        & p {
            /* text-align: center; */
            font-size: 1rem;
            width: 100%;
        }
      }
  }
  