/* Cover for the left and right margins */
.cover {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 10%; /* Covers 10% of the screen width */
    background-color: #121212; /* Same color as the background */
    z-index: 100; /* Ensure covers are on top */
    height: 100%;
  }
  
  .left-cover {
    left: 0;
  }
  
  .right-cover {
    right: 0;
  }

.content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200vh; /* Ensure enough scrolling space */
    margin: 0 10%; /* 10% margin left and right */
    color: #ffffff; /* White text for dark theme */
    position: relative; /* Content is below the logo */
    z-index: 1; /* Ensure content is behind the logo initially */
    background-color:#121212;
  }
  